/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { graphql } from "gatsby"
import { StaticImage, getImage } from "gatsby-plugin-image"
import Panel from "../components/panel/panel"
import Layout from "../components/layout/layout"
import Metadata, { buildSocialImage } from "../components/metadata/metadata"
import SquareList from "../components/list-square/list-square"
import { colors } from "../gatsby-plugin-theme-ui"

export default function PerformPlatform({ data }) {
  const socialImage = buildSocialImage({
    title: data.imageSocial.name,
    ...getImage(data.imageSocial),
  })

  return (
    <Layout>
      <Metadata
        title="Create your own immersive learning"
        description="Creating high-quality, interactive learning content at scale has been time consuming, expensive to produce, and hard to standardize — until now. Moth+Flame’s revolutionary Create tool allows you to efficiently create and deliver immersive learning to an enterprise-wide audience across a limitless array of customizable topics."
        image={socialImage}
      />
      <Panel
        sx={{
          background: `linear-gradient(to bottom, ${colors.bgGray}, ${colors.bgGray} 50%, #fff 50%)`,
          pt: ["4rem", "6rem", "8rem"],
        }}
      >
        <StaticImage
          placeholder="none"
          src="../images/create.png"
          alt="Create"
          sx={{
            gridColumnStart: [2, 3, 5],
            gridColumnEnd: ["span 2", "span 4"],
            mx: [null, "6rem", "3rem"],
            mb: "4rem",
          }}
        />
        <div sx={{ gridColumn: "1 / -1", textAlign: "center" }}>
          <Themed.h1 className="scaleText" sx={{ mb: "0 !important" }}>
            Create your own immersive learning
          </Themed.h1>
          <Themed.h2
            sx={{
              fontWeight: "normal",
              fontSize: ["1.6rem", "2.4rem", "3rem"],
            }}
          >
            Use AI to generate custom scenario-based immersive learning in
            minutes
          </Themed.h2>
        </div>
        <div
          sx={{
            gridColumnStart: ["1", "3", "4"],
            gridColumnEnd: ["-1", "span 4", "span 6"],
            mb: ["4rem", "unset"],
          }}
        >
          <div
            sx={{
              p: [1, 3, 4],
              mt: "20%",
              mb: "8rem",
              bg: "white",
              border: "solid 1px #e8e8e8",
              borderRadius: "1.2rem",
              boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.16)",
              position: "relative",
              "&:after": {
                content: '""',
                position: "absolute",
                bottom: "10px",
                left: "20px",
                display: "block",
                width: "90%",
                height: ["30px", "40px", "58px"],
                bg: "white",
              },
            }}
          >
            <div
              sx={{
                mt: ["-25%", null, "-30%"],
                mb: [null, null, "-4rem"],
                mx: ["6rem", null, "8rem"],
              }}
            >
              <StaticImage
                placeholder="none"
                src="../images/ipad-headset.png"
                alt="iPad + VR headset"
              />
            </div>
            <iframe
              src="https://www.chatbase.co/chatbot-iframe/dep6tS2f9WTXjNgirfnfv"
              width="100%"
              height="700"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </Panel>
      <Panel
        sx={{ pb: ["4rem", "6rem", "8rem"], fontSize: ["1.6rem", "2rem"] }}
      >
        <div
          sx={{
            gridColumnStart: ["1", "3", "4"],
            gridColumnEnd: ["-1", "span 4", "span 6"],
            mb: ["4rem", "unset"],
          }}
        >
          <Themed.p>
            Creating high-quality, interactive learning content at scale has
            been time consuming, expensive to produce, and hard to standardize —
            until now.
          </Themed.p>
          <Themed.p>
            Moth+Flame’s revolutionary Create tool allows you to efficiently
            create and deliver immersive learning to an enterprise-wide audience
            across a limitless array of customizable topics.
          </Themed.p>
          <div
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              textAlign: "center",
              color: "#fd2569",
              mb: ["4rem", null, "6rem"],
            }}
          >
            <div>
              <StaticImage
                placeholder="none"
                src="../images/macbook-authoring-tool.png"
                alt="Computer with authoring tool"
              />
              Easy-to-Use Authoring
            </div>
            <div>
              <StaticImage
                placeholder="none"
                src="../images/macbook-with-avatars.png"
                alt="Computer with avatar selection"
              />
              AI-Generated Video
            </div>
          </div>
          <SquareList>
            <li>
              Efficiently create high-quality, interactive learning content at
              scale
            </li>
            <li>
              Deliver immersive learning to an enterprise-wide audience across
              customizable topics
            </li>
            <li>
              Utilize generative AI to reduce traditional video production costs
              by 10X
            </li>
            <li>Improve learner retention and engagement</li>
            <li>
              Standardized format for consistency and accessibility across all
              learning materials
            </li>
            <li>Track and measure effectiveness for continuous improvement</li>
            <li>Publish to VR, tablet, and mobile</li>
            <li>User-friendly design and easy-to-use workflow</li>
            <li>
              Flexible pricing options including self-service and white-glove
              content development from award-winning studio
            </li>
          </SquareList>
        </div>
      </Panel>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    imageSocial: file(name: { eq: "ipad-headset" }) {
      name
      childImageSharp {
        gatsbyImageData(width: 1200, layout: FIXED, formats: JPG)
      }
    }
  }
`
